import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { LocationService } from 'src/Services/location.service';
import { PaginationModel } from 'src/Models/paginationModel';
import { Globle } from 'src/app/Shared/global';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { LocationSearchModel } from 'src/Models/locationsearchModel';
import { Title } from "@angular/platform-browser";
import { OnlineRegisterService } from 'src/Services/onlineRegister.services';
import { OnlineRegistrationModel } from 'src/Models/onlineRegistrationModel';
import * as FileSaver from 'file-saver';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { filterParameter } from 'src/Models/filterParameter';
import { UsersService } from 'src/Services/users.service';

@Component({
  selector: 'app-online-registration-listing',
  templateUrl: './online-registration-listing.component.html',
  styleUrls: ['./online-registration-listing.component.css']
})
export class OnlineRegistrationListingComponent implements OnInit {
  providerId: number = 0;
  listOfData: OnlineRegistrationModel[];
  pagination: PaginationModel = new PaginationModel();
  isloading: boolean = false;
  Id: number = 0;
  isAscending: boolean;
  searchTextChanged = new Subject<string>();

  pageSize: number = 0;
  pageIndex: number = 0;
  totalRecordsCount: number = -1;

  upDowFirstNameCSS: string = '';
  upDowLastNameCSS: string = '';
  upDowEmailCSS: string = '';
  upDowPhoneCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowConatactTypeCSS: string = '';
  upDowAPIStatusCSS: string = '';
  upDowEnrollStatusCSS: string = '';

  deletedId: number = 0;
  @ViewChild('closeDeleteConfirmation') closeDeleteConfirmation: ElementRef;

  listOfExportsData: OnlineRegistrationModel[];
  paginationExport: PaginationModel = new PaginationModel();

  isDeleteButtonShow: boolean = false;

  isDocloading: boolean = false;
  filterParameter = new filterParameter();

  constructor
    (
      private router: Router,
      private onlineRegisterService: OnlineRegisterService,
      private globle: Globle,
      private titleService: Title,
      private toastr: ToastrService,
      private userService: UsersService,
    ) {
    this.pagination.orderBy = 'CreatedDate Desc';
    this.pagination.pageNumber = 1;
    this.pagination.pageSize = 10;
    this.pagination.userId = this.globle.cUser.id.toString();
    this.titleService.setTitle("Online Registration");
    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
      this.isDeleteButtonShow = false;
    else
      this.isDeleteButtonShow = true;
  }


  ngOnInit() {
    movetotop();
    this.providerId = this.globle.cUser.providerId;
    this.Id = (this.globle.cUser.userGroupId == 4 || this.globle.cUser.userGroupId == 5) ? +this.globle.cUser.locationId : 0;

    this.GetOnlineRegistrationByPaged();

  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.pagination.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';
    switch (sortBy) {
      case 'firstName': this.upDowFirstNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'lastName': this.upDowLastNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'emailId': this.upDowEmailCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'phone': this.upDowPhoneCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'createdDate': this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'conatactType': this.upDowConatactTypeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'apiStatus': this.upDowAPIStatusCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'enrollStatus': this.upDowEnrollStatusCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetOnlineRegistrationByPaged();
  }

  cleanCssClass() {
    this.upDowFirstNameCSS = '';
    this.upDowLastNameCSS = '';
    this.upDowEmailCSS = '';
    this.upDowPhoneCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowConatactTypeCSS = '';
    this.upDowAPIStatusCSS = '';
    this.upDowEnrollStatusCSS = '';
  }

  keywordFilters($event) {
    this.isloading = true;
    this.pagination.pageNumber = 1;
    this.pagination.whereCondition = $event.target.value;
    this.GetOnlineRegistrationByPaged();
  }

  pageChanged($event: any) {
    this.pagination.pageNumber = 1;
    this.pagination.pageNumber = parseInt($event.page);
    this.GetOnlineRegistrationByPaged();
  }

  GetOnlineRegistrationByPaged() {
    this.isloading = true;
    this.onlineRegisterService.GetOnlineRegistrationByPaged(this.pagination).subscribe(
      data => {
        this.isloading = false;
        this.listOfData = data;
        this.paginationExport = Object.assign({}, this.pagination);
        this.paginationExport.pageNumber = 1;
        this.paginationExport.pageSize = 10000;
        this.paginationExport.whereCondition = '';
        if (this.listOfData != null) {
          if (this.listOfData.length > 0) {
            this.totalRecordsCount = this.listOfData[0].totalRecordCount;
          }
          else
            this.totalRecordsCount = 0;
        }
        else
          this.totalRecordsCount = 0;
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  redirectToAddNew(id, apiStatus, applicationHistoryId) {

    if (sessionStorage.getItem('applicationHistoryId') != null)
      sessionStorage.removeItem('applicationHistoryId');
    if (sessionStorage.getItem('isFrontEligibliityComplated') != null)
      sessionStorage.removeItem('isFrontEligibliityComplated');

    if (apiStatus == 'COMPLETE')
      sessionStorage.setItem('isFrontEligibliityComplated', 'true')
    else
      sessionStorage.setItem('isFrontEligibliityComplated', 'false')

    sessionStorage.setItem('isOnlineApplicationHistoryId', applicationHistoryId)
    this.router.navigate(['admin/applicants/addnew/' + id]);
  }

  GetDataForExportOnlineRegistrationByPaged() {
    this.isDocloading = true;
    this.onlineRegisterService.GetOnlineRegistrationByPagedExport(this.paginationExport).subscribe(
      data => {
        if (data != null) {
          this.isDocloading = false;
          if (data.status == 'Success') {
            this.filterParameter.path = data.responseNewFilename;
            this.userService.DownloadDocument(this.filterParameter).subscribe(
              fData => {
                this.isDocloading = false;

                FileSaver.saveAs(fData, "OnlineRegistration.csv");
              },
              error => {
                console.log(error);
              }
            );
          }
          else {
            this.isDocloading = false;
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  exportToExcel() {

    let tableData = document.getElementById("ExportTable").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    // let html = "<table><tr class='mainhead'> <td colspan='3'>Location:" + ((this.selectedLocation != undefined && this.selectedLocation.length > 0) ? this.selectedLocation[0].name : 'All') + "</td>";
    // html += "</tr><tr class='mainhead'>    <td colspan='3'> From Year: " + this.filter.FromYear + " To: " + (this.filter.ToYear != undefined ? this.filter.ToYear : '') + "</td>  </tr>";
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    // html += "<tr><td style='font-weight: bold;'>Total</td><td>" + this.analyticModel.new_Approve_Count + "</td><td>" + this.analyticModel.reNew_Reject_Count + "</td></tr>"
    // html += "</table>";
    let name = 'OnlineRegistration';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "OnlineRegistration.xls");
  }

  setDeleteOnlineRegistration(id) {
    this.deletedId = id;
  }

  deleteOnlineRegistration(id) {
    this.onlineRegisterService.DeleteOnlineRegistrationFindById(this.deletedId).subscribe(
      data => {
        this.deletedId = 0;

        if (data > 0) {
          this.toastr.success('Record deleted successfully', 'Delete');
          this.closeDeleteConfirmation.nativeElement.click();
          this.GetOnlineRegistrationByPaged();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  checkStatus(emailId) {
    sessionStorage.setItem('chkEmail', emailId)
    this.router.navigate(['admin/status/check']);
  }
}
